/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_WHITE,
	colors,
	media,
	THEME_CITRON,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'
import WorkShowcaseMobile, {
	WORK_SHOWCASE_LAYOUT_MOBILE_4UP,
} from '../../components/content/WorkShowcaseMobile'

import Showcase, {
	SHOWCASE_LAYOUT_1UP,
} from '../../components/content/Showcase'
import { CopyButtonInline } from '../../components/CopyButton'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.creditKarma.next

type Props = {
	accentTheme: string
}

const CreditKarmaPage: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		fragment MobileScreen on File {
			childImageSharp {
				fixed(width: 720, height: 1280, quality: 100, cropFocus: NORTH) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		}

		query {
			mosaic: file(relativePath: { eq: "credit-karma/mosaic.png" }) {
				...ImageFluidFull
			}
			color: file(relativePath: { eq: "credit-karma/color.png" }) {
				...ImageFluidFull
			}

			mobileUI0: file(relativePath: { eq: "credit-karma/mobile-ui-0.png" }) {
				...MobileScreen
			}
			mobileUI1: file(relativePath: { eq: "credit-karma/mobile-ui-1.png" }) {
				...MobileScreen
			}
			mobileUI2: file(relativePath: { eq: "credit-karma/mobile-ui-2.png" }) {
				...MobileScreen
			}
			mobileUI3: file(relativePath: { eq: "credit-karma/mobile-ui-3.png" }) {
				...MobileScreen
			}

			pluginMosaic: file(
				relativePath: { eq: "credit-karma/plugin-mosaic.png" }
			) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<Fragment>
			<Helmet>
				<title>Credit Karma</title>
				<meta content="Credit Karma Case Study" property="og:title" />
				<meta content="Credit Karma Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="Design tooling and automation to improve data visualization across the #1 credit monitoring platform."
				/>
				<meta
					name="description"
					content="Design tooling and automation to improve data visualization across the #1 credit monitoring platform."
				/>
				<meta
					content="https://freeassociation.com/share/credit-karma.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/credit-karma.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Credit Karma</h1>
							<p className="--headline">
								Charting a path to product innovation.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Product design within any organization can be a challenge.
									Product design that needs to incorporate data visualizations
									takes that complexity to an entirely different level. We
									partnered with Credit Karma to widen the creative capacity of
									its design team with a robust library of data visualization
									elements. The library and complementary tools arm designers
									with easy to use charts, graphs and helpful guidance. With
									these in hand, Credit Karma designers became equipped for
									efficient experimentation with innovative ways to present data
									to their users.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Data Visual Library</li>
									<li>Brand &amp; Visual Concepting</li>
									<li>Brand System & Guidelines</li>
									<li>Figma Plugin</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage fluidImageData={data.mosaic.childImageSharp.fluid} />
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We built a robust library of data visualization components
									that slide seamlessly into any Credit Karma application,
									unlocking creativity and experimentation at scale.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Highly Composable">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										The components are made to creatively fit within any Credit
										Karma experience, making no assumptions about their intended
										surroundings.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Realistic Data">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										A spectrum of example datasets gave designers the tools they
										need to depict real-world scenarios for realistic user
										testing.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Dozens of Variants">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Designers can quickly fine-tune data visualizations for
										their specific needs through purposefully defined variants
										and nuanced controls.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Consistent Style">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Each component has Credit Karma’s brand expertly woven into
										its visual attributes, focusing designers on outstanding UX
										instead of color and type.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={2540} height={1440} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/media/credit-karma/FA_CS_CreditKarma_Charts-Loop.mp4" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<Showcase
						layout={SHOWCASE_LAYOUT_1UP}
						chrome={false}
						images={[data.color.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<WorkShowcaseMobile
						layout={WORK_SHOWCASE_LAYOUT_MOBILE_4UP}
						items={[
							{ src: data.mobileUI0.childImageSharp.fixed.src },
							{ src: data.mobileUI1.childImageSharp.fixed.src },
							{ src: data.mobileUI2.childImageSharp.fixed.src },
							{ src: data.mobileUI3.childImageSharp.fixed.src },
						]}
						showChrome={true}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We developed a custom Figma plugin to quickly guide and
									educate Credit Karma designers towards appropriate chart usage
									based on UX intent.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Tightly Integrated">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Meeting designers where they are —in their primary design
										tool— removes the need for documentation sites and external
										guidelines.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Beyond the Basics">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Not limited to simple base components, the plugin supplies
										pre-configured variants for deeper exploration and
										contextual usage.
									</p>
								</Text>
							</Highlight>
							<Highlight title="A Menu of Ideas">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										The plugin encourages designers to try different components
										that are appropriate for their general UX scenario.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Easy Maintenance">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Credit Karma became equipped to manage the contents of the
										plugin palette right within Figma. No developers required.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={2540} height={1440} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/media/credit-karma/FA_CS_CreditKarma_Plugin-Recording.mp4" />
					</VideoLoop>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.pluginMosaic.childImageSharp.fluid}
					/>
				</div>
			</Slide>

			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--large">
								Have a design tooling and automation challenge?
							</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(CreditKarmaPage, THEME_CITRON)
